import React from 'react';
import { Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useAuth from '#/hooks/useAuth';

type LinkMap = {
  [key: string]: string;
};

const useHighlightWords = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const excludedPathnames = [
    '/digitaler-nachlass',
    '/vermoegens-und-sachregister',
  ];

  function highlightWords(
    sentence: string,
    targetedWords: string[],
    targetLink?: string
  ) {
    if (excludedPathnames.includes(location.pathname)) {
      return [sentence];
    }

    let elements: React.ReactNode[] = [sentence];
    targetedWords.forEach((phrase, i) => {
      elements = elements.flatMap((el, j) => {
        if (typeof el === 'string') {
          const parts = el.split(new RegExp(`(${phrase})`, 'gi'));
          const checkLink = (phrase: string): string => {
            const authLinkMap: LinkMap = {
              Patientenverfügung: '/dashboard/patientenverfuegung',
              'the living will': '/dashboard/patientenverfuegung',
              Vorsorgeauftrag: '/dashboard/vorsorgeauftrag',
              'the power of attorney': '/dashboard/vorsorgeauftrag',
              Testament: '/dashboard/testament',
              'the last will': '/dashboard/testament',
            };

            const publicLinkMap: LinkMap = {
              Patientenverfügung: '/patientenverfuegung',
              'the living will': '/patientenverfuegung',
              Vorsorgeauftrag: '/vorsorgeauftrag',
              'the power of attorney': '/vorsorgeauftrag',
              Testament: '/testament',
              'the last will': '/testament',
            };

            const linkMap = isAuthenticated ? authLinkMap : publicLinkMap;

            return linkMap[phrase] ?? '#';
          };

          const newTabLinks =
            targetLink === 'https://www.ztr.ch/notariatsinfo/' ||
            targetLink === 'https://www.ztr.ch';

          return parts.map((part, k) =>
            part.toLowerCase() === phrase.toLowerCase() ? (
              <Link
                href={targetLink ?? checkLink(phrase) ?? '#'}
                // target="_blank"
                key={`${i}-${j}-${k}`}
                sx={{ color: '#3366FF', cursor: 'pointer' }}
                target={newTabLinks ? '_blank' : '_self'}
              >
                <Typography
                  sx={{
                    textDecoration: 'none',
                    display: 'inline',
                  }}
                >
                  {part}
                </Typography>
              </Link>
            ) : (
              part
            )
          );
        }
        return el;
      });
    });
    return elements;
  }
  return highlightWords;
};

export default useHighlightWords;
